export default {
  "nav_our_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestro programa"])},
  "nav_about_HPM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acerca de TPM"])},
  "nav_blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
  "nav_testimonies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testimonios"])},
  "nav_contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctanos"])},
  "nav_view_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver Demos"])},
  "nav_locale_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglés"])},
  "nav_locale_es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
  "nav_tpm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPM® Login"])},
  "banner_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planificar, aprender y crecer juntos..."])},
  "banner_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPM® será su compañero de clase en casa, te ayudaremos a superar las tareas escolares diarias y juntos construiremos un futuro brillante para tus hijos."])},
  "banner_free_trial_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicita un Demo"])},
  "banner_contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctanos"])},
  "team_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestro Equipo"])},
  "member_luccas_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departamento de soporte"])},
  "member_luccas_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi nombre es Luccas Medina, soy el encargado de realizar las instalaciones y servicio al cliente. Actualmente tengo 4 años de experiencia com ingeniero en networking. Mis pasatiempos generalmente consisten en cantar y jugar basket. En mi tiempo libre disfruto de los videojuegos y leer. Sobre mis gustos literarios, mis libros favoritos son: 11 anillos y mentalidad de Mamba. "])},
  "member_luccas_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio- Gerente del departamento de instalación"])},
  "member_luccas_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone:"])},
  "member_luccas_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 8635 Florence Ave. S-205 Downey, CA 90240"])},
  "member_luccas_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_luccas_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
  "member_luccas_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_edgar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edgar Chavez"])},
  "member_edgar_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy Edgar, tengo 22 años entrenando personas y 7 años en programas educativos, mis libros preferidos son: El camino del Lobo, Piense y Hagase Rico, me gusta ir al cine, leer, estudiar, coaching, en mi tiempo de descanso me gusta convivir en familia e ir de compras."])},
  "member_edgar_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chavez Services"])},
  "member_edgar_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono:"])},
  "member_edgar_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Downey, CA 90240"])},
  "member_edgar_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo eléctronico "])},
  "member_edgar_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio web "])},
  "member_edgar_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_eduardo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eduardo Porras"])},
  "member_eduardo_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_eduardo_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studia"])},
  "member_eduardo_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downey, CA 90240"])},
  "member_eduardo_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo eléctronico "])},
  "member_eduardo_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio web "])},
  "member_eduardo_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_erick_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erick Lopez"])},
  "member_erick_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi nombre es Erick Lopez Rodriguez, soy asesor de educación. Actualmente tengo 3 años como maestro/consejero SDA chruch, 8 años como vendedor de educación. Mis pasatiempos generalmente consisten en cantar, tocar guitarra. Sobre mis gustos literarios , mis libros favoritos son: La Biblia, El deseado de todos los siglos, El conflicto de todos los siglos y 3 pequeñas mujeres."])},
  "member_erick_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compañia: Erick Education"])},
  "member_erick_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono:"])},
  "member_erick_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downey, CA 90240"])},
  "member_erick_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo eléctronico "])},
  "member_erick_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio web "])},
  "member_erick_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_elizabeth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elizabeth Cruz"])},
  "member_elizabeth_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_elizabeth_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E Cruz Services"])},
  "member_elizabeth_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono:"])},
  "member_elizabeth_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downey, CA 90240"])},
  "member_elizabeth_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo eléctronico "])},
  "member_elizabeth_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio web "])},
  "member_elizabeth_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_gricelda_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gricelda Lopez"])},
  "member_gricelda_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi nombre es Gricelda Lopez, soy representante ejecutivo. Tengo 20 años de experiencia como supervisora en el área educativa, hace 5 años visitando familias para orientarlos de cómo ayudar a sus hijos a que un día logren llegar a la universidad.Mis pasatiempos generalmente consisten en pasear en bicicleta por la playa, disfrutar de mi familia, cocinar lo que les gusta, pasear mis perritas. Sobre mis gustos literarios, mis libros favoritos son: Tus zonas erróneas, Piense, el camino del lobo.  "])},
  "member_gricelda_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Gricelda E Services"])},
  "member_gricelda_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono:"])},
  "member_gricelda_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downey, CA 90240"])},
  "member_gricelda_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo eléctronico "])},
  "member_gricelda_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio web "])},
  "member_gricelda_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_griselda_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Griselda Guerrero"])},
  "member_griselda_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_griselda_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guerrero Services"])},
  "member_griselda_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono:"])},
  "member_griselda_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downey, CA 90240"])},
  "member_griselda_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo eléctronico "])},
  "member_griselda_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio web "])},
  "member_griselda_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_jennifer_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jennifer Gutierrez"])},
  "member_jennifer_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_jennifer_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J Gutierrez Services"])},
  "member_jennifer_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone:"])},
  "member_jennifer_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Downey, CA 90240"])},
  "member_jennifer_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_jennifer_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
  "member_jennifer_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_patricia_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patricia Linares "])},
  "member_patricia_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi nombre es Patricia Linares, soy representante educativo. Tengo 15 años de experiencia supervisando y entrenando grupos y 6 años como asesora educativa. Mis pasatiempos generalmente consisten en la Jardineria y salir de compras. En mi tiempo libre isfruto una buena cena y poder caminar por la costa. Sobre mis gustos literarios, mis libros favoritos son: El poder de la metamorfosis de Salvador Carrion;novelas de Isabel Allende entre otros."])},
  "member_patricia_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" P. Lindsay"])},
  "member_patricia_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono:"])},
  "member_patricia_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downey, CA 90240"])},
  "member_patricia_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_patricia_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
  "member_patricia_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_ricardo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricardo Poblete"])},
  "member_ricardo_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi nombre es Ricardo Poblete, tengo 60 años, mi profesión es Ing.civil y Administración de Negocios. Mi hobby es la lectura y la medicina natural, mi libro favorito: Los Miserables"])},
  "member_ricardo_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricardo Poblete"])},
  "member_ricardo_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono:"])},
  "member_ricardo_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downey, CA 90240"])},
  "member_ricardo_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_ricardo_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
  "member_ricardo_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_gabriel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabriel Sanchez"])},
  "member_gabriel_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_gabriel_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabriel Sanchez"])},
  "member_gabriel_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_gabriel_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_gabriel_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_gabriel_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
  "member_gabriel_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_laura_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laura Somera"])},
  "member_laura_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi nombre es Laura Somera, soy Licenciada en Turismo de la Universidad de Guadalajara, Mi Hobby es leer y bailar. Libro favorito: The go getter."])},
  "member_laura_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laura Somera"])},
  "member_laura_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_laura_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_laura_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_laura_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
  "member_laura_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_steven_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steven Smith"])},
  "member_steven_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi nombre es Steven, tengo 57 years años, 33 Yrs como educador, Mis estudios en: B.A. English WVU, Libro Favorito: La Biblia, Mi Hobby: Los deportes"])},
  "member_steven_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scholastic Advancement System"])},
  "member_steven_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_steven_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_steven_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_steven_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
  "member_steven_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_marvin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marvin Solares"])},
  "member_marvin_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi nombre es Marvin Solares, tengo 58 años. Mis pasatiempos son andar en bicicleta,ir al gimnasio y caminar en el parque.Mi libro favorito:Como ganar amigos"])},
  "member_marvin_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marvin Solares"])},
  "member_marvin_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_marvin_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_marvin_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_marvin_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
  "member_marvin_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_gloria_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gloria Mondragon"])},
  "member_gloria_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_gloria_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gloria Mondragon"])},
  "member_gloria_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_gloria_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_gloria_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_gloria_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
  "member_gloria_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_juanita_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juanita Montoya"])},
  "member_juanita_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_juanita_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juanita Montoya"])},
  "member_juanita_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_juanita_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_juanita_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_juanita_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
  "member_juanita_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_yessica_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yessica Ruiz"])},
  "member_yessica_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_yessica_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yessica Ruiz"])},
  "member_yessica_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_yessica_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_yessica_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_yessica_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
  "member_yessica_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_luz_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luz Elena Llano"])},
  "member_luz_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_luz_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luz Elena Llano"])},
  "member_luz_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_luz_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_luz_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_luz_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
  "member_luz_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_luis_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luis Nuñez"])},
  "member_luis_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_luis_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luis Nuñez"])},
  "member_luis_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_luis_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_luis_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_luis_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
  "member_luis_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "footer_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Este distribuidor autorizado de TPM®, es un contratista independiente"])},
  "footer_new_installer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalador"])},
  "request_contact_tit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctanos"])},
  "request_contact_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dejanos tus datos para poder realizar una demostración de nuestro producto."])},
  "request_contact_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
  "request_thks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias!"])},
  "request_thks_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronto un representate se comunicará contigo para atender tu solicitud!"])},
  "request_tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inténtalo nuevamente"])},
  "request_tryAgain_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pudimos registrar tus datos, por favor intentalo nuevamente o contáctanos via"])},
  "btn_sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regístrate"])}
}